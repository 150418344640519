# Copyright (C) 2022 Covram Pty Ltd. All rights reserved.
# Do not distribute without the express permission of the author.

<template>
  <div>
    <v-container>
      <v-row
        class="py-6"
      >
        <v-col
          class="col-md-6 offset-md-3"
        >
          <h1 class="display-4 my-6">
            Getting Started
          </h1>
          <p>
            Covram is built on the Mergin Maps open source project, and consists of the following components:
          </p>
          <ul>
            <li>QGIS - A free open source desktop application to analyse your data</li>
            <li>Mergin Maps plugin for QGIS - Used to link QGIS with Covram Cloud</li>
            <li>Covram Cloud - Saas Cloud Service used to create, manage, share and sync projects between devices</li>
            <li>Mergin Maps Input - iOS and Android mobile app to collect field data</li>
            <li>Covram Framework - Rapid assessment methodology integrated into the input app</li>
          </ul>
          <h2 class="display-3 mt-4 mb-2">Installation and Registration</h2>
          <h4 class="display-1 mt-4 mb-2">Covram Cloud</h4>
          <ol>
            <li>Visit <a href="https://covram.com.au">covram.com.au</a> on your browser.</li>
            <li>Click the <strong>Register</strong> button on the top right corner.</li>
            <li>Complete the form and click the <strong>Sign Up</strong> button.</li>
            <li>Click the <strong>New Project</strong> button to create a new test project.</li>
            <li>Enter the project name, for example create a test project named <strong>Test Covram Project</strong>.</li>
            <li>Click on the <strong>Template project</strong> dropdown box and select the most recent <strong>Covram</strong> project template.</li>
            <v-img
              src="@/assets/ProjectCreationScreenshot.png"
              max-height="300"
              class="mr-auto"
              contain
            ></v-img>
            <li>Click the <strong>Create</strong> button to create the project.</li>
          </ol>
          <h4 class="display-1 mt-4 mb-2">Mergin Maps Input</h4>
            <ol>
              <li>Visit the Google Play or Apple App Store and search for the <strong>Mergin Maps</strong> app.</li>
              <li>Download and install the app.</li>
              <li>Open the Mergin Maps app on your device.</li>
              <li>Go to the <strong>Home</strong> tab.</li>
              <li>Tap the <strong>Profile</strong> icon in the top right of the <strong>Projects</strong> screen to open the <strong>Login</strong> screen.</li>
              <li>Enter the <strong>Username</strong> and <strong>Password</strong> you used to register for Covram Cloud.</li>
              <li>Tap the <strong>Pen</strong> icon at the bottom of the screen and update the app url to <strong>https://covram.com.au/</strong>.</li>
              <li>Tap the <strong>Tick</strong> icon to approve the change in url, and tap the <strong>Sign in</strong> button.</li>
              <v-img
                src="@/assets/MobileLoginScreenshot.png"
                max-height="500"
                class="mr-auto"
                contain
              ></v-img>
              <li>You should now have access to any Covram projects you create (such as Test Covram Project) on your Mergin Maps app.</li>
            </ol>
          <h4 class="display-1 mt-4 mb-2">QGIS</h4>
          <ol>
            <li>Visit the <a href="https://qgis.org/en/site/forusers/download.html">QGIS downloads page</a>.</li>
            <li>Find the appropriate installer for your operating system and click to download.</li>
            <li>Run the installer once it has finished installing and follow the prompts using the default installation options.</li>
          </ol>
          <h4 class="display-1 mt-4 mb-2">Mergin Maps plugin for QGIS</h4>
          <ol>
            <li>Open QGIS on your computer.</li>
            <li>Click on the <strong>Plugins</strong> tab and select the <strong>Manage and Install Plugins...</strong> option.</li>
            <li>Enter <strong>Mergin</strong> in the search box and select the <strong>Mergin</strong> plugin.</li>
            <li>Click the <strong>Install Plugin</strong> button.</li>
            <li>Once installation has completed, close the plugins dialog. The Mergin Maps Toolbar should now appear in QGIS</li>
            <li>Click on the 'cog' icon on the Mergin Maps Toolbar to configure the Mergin Maps Plugin.</li>
            <li>Enter the <strong>username</strong> or <strong>email</strong> you used to register for Covram Cloud.</li>
            <li>Select the <strong>Save credentials</strong> and <strong>Custom Mergin Maps server</strong> checkboxes.</li>
            <li>In the field next to the <strong>Custom Mergin Maps server</strong> checkbox enter the url <strong>https://covram.com.au<strong>.</li>
            <v-img
              src="@/assets/CovramMerginMapsConfig.png"
              max-height="346"
              class="mr-auto"
              contain
            ></v-img>
            <li>
              Mergin Maps plugin should now be able to load projects (such as Test Covram Project) from the Covram Cloud in QGIS. Click <strong>Test Connection</strong> to confirm
               that everything is working then click <strong>OK</strong>.
             </li>
           </ol>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'getting-started'
}
</script>

<style scoped lang="scss">
  .sidebar {
    height: 100vh;
    background-color: #4caf50;
    top: 0px;
    max-height: calc(100% + 0px);
    transform: translateX(0%);
    width: 260px;
  }

  .v-navigation-drawer {
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;
    top: 0;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    will-change: transform;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform;
  }

  .bubble {
    width: 800px;
    background-color: #eaebef;
    padding: 20px 25px 15px 15px;
  }

  .app-store-button {
      padding-top: 12px;
    }

  @media only screen and (max-width: 599px) {
    .app-store-button {
      padding-left: 12px;
      padding-top: 0px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 960px) {
    .app-store-button {
      margin-left: 20%;
    }
  }

  h3 {
    color: #2d4470;
  }
</style>
