import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '../i18n'
// import '../sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  // primary: '#4CAF50',
  primary: '#111111', // black
  secondary: '#2a2a2a', // purple
  accent: '#78d98f', // green
  background: '#c8efd1',
  info: '#00CAE3' // cyan
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
