# Copyright (C) 2018 Covram Pty Ltd. All rights reserved.
# Do not distribute without the express permission of the author.

<template>
  <v-app-bar
    absolute
    color="transparent"
    elevate-on-scroll
    scroll-target="#hero"
  >
    <v-container class="px-0 fill-height">
      <v-img
        src="@/assets/CovramLogoWhite.svg"
        max-height="40"
        max-width="100"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <div
        v-if="app.user"
      >
        <v-btn
          :to="{name: 'dashboard'}"
        >
          Go to my account
        </v-btn>
      </div>
      <div
        v-else
      >
        <v-btn
          large
          rounded
          elevation="2"
          :to="{name: 'login'}"
        >
          Log In
        </v-btn>
        <v-btn
          large
          rounded
          elevation="2"
          :to="{name: 'register'}"
        >
          Register
        </v-btn>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import OrganisationMixin from '../mixins/Organisation'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'menu-header',
  props: {
    isPrimary: {
      type: Boolean,
      default: true
    }
  },
  mixins: [OrganisationMixin],
  computed: {
    ...mapState(['organisations', 'drawer']),
    app () {
      return this.$store.state.app
    },
    organisations () {
      return this.$store.state.organisations
    },
    namespace () {
      return this.$store.state.namespace
    },
    profileUrl () {
      return { name: 'user', params: { username: this.app.user.username } }
    }
  },
  created () {
    if (this.app.user) {
      // to have all available namespace for project creation
      this.getUserOrganisations()
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    }),
    logout () {
      this.$http.get('/auth/logout').then(() => {
        if (this.$route.path === '/') {
          location.reload()
        } else {
          location.href = '/'
        }
      })
    },
    clearUserData () {
      this.$store.dispatch('clearUserData')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 0 0.5em;
  position: relative;
  min-height: 50px;
  flex-shrink: 0;
  z-index: 7;
  .content {
    height: 50px;
    align-items: flex-end;
  }
  .v-btn:hover:before {
    background-color: #fff;
  }
  .v-btn:before {
    background-color: transparent;
  }
  .v-btn {
    letter-spacing: normal;
  }

  a {
    color: #fff;
    min-width: 2em;
    margin: 0.35em 0.25em;
    padding: 0.35em 0.75em;
    text-decoration: none;
    font-weight: 500;
    /*font-size: 110%;*/
    font-size: 15.4px;
    &.active {
      color: orange;
    }
    &.logo {
      padding: 0;
      margin: 0 1em;
      height: inherit;
      position: relative;
      img {
        height: inherit;
        width: auto;
        padding: 0.25em 0;
      }
      .banner {
        position: absolute;
        top: 7px;
        left: 2px;
        color: orange;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}
@media (min-width : 960px) {
  .small-screen {
    display: none;
  }
}
.v-btn {
  text-transform: none;
}
.menu-sub-title {
  font-weight: 700 !important;
}
.menu-sub-content {
  padding-left: 16px;
  cursor: pointer;
  width: 100%;
}
.menu-sub-content:hover,.pointer:hover {
  opacity: 0.5;
}
.pointer {
  cursor: pointer;
}
  .icon-btn:before {
    display: none;
  }
</style>
