# Copyright (C) 2022 Covram Pty Ltd. All rights reserved.
# Do not distribute without the express permission of the author.

<template>
  <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
      &copy; {{ new Date().getFullYear() }} Covram Pty Ltd. All rights reserved.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
     .layout {
     width: 100%;
     max-width: 1140px;
   }
   .footer {
    background-color: #eaebef;
     max-height: 70px;
    a {
      text-decoration: none;
      &.icon:hover > i {
        color: #555;
      }
    }
     .icon {
    width: 1.5em;
    height: 1.5em;
    margin: 0.5em;
    color: grey;
  }
  }
</style>
