# Copyright (C) 2022 Covram Pty Ltd. All rights reserved.
# Do not distribute without the express permission of the author.

<template>
  <div>
    <v-container fluid class="pa-0" id="hero">
      <v-parallax
        height="800"
        dark
        src="@/assets/SurveyingBackground.jpg"
      >
        <v-overlay
          absolute
          value="true"
          opacity="0.4"
          >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              class="text-center"
              cols="12"
            >
              <v-img
                src="@/assets/CovramLogoWhite.svg"
                max-height="100"
                contain
              ></v-img>
              <h4 class="headline">
                Easily understand vegetation condition
              </h4>
            </v-col>
          </v-row>
        </v-overlay>
      </v-parallax>
    </v-container>
    <v-container>
      <v-row
        align="center"
        justify="center"
        class="py-6"
      >
        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          <h2 class="display-2">
            Based on over 30 years of experience in native vegetation assessments
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
          md="4"
        >
          <v-icon
            x-large
          >
            mdi-check
          </v-icon>
          <h3 class="headline">
            Easy to use
          </h3>
          <p class="py-3">
            Capture data easily with your mobile or tablet
          </p>
          <p class="py-3">
            Developed for land managers
          </p>
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          md="4"
        >
          <v-icon
            x-large
          >
            mdi-monitor-cellphone
          </v-icon>
          <h3 class="headline">
            Latest technology
          </h3>
          <p class="py-3">
            Offline capability for remote locations
          </p>
          <p class="py-3">
            Powerful integration with QGIS open source software
          </p>
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          md="4"
        >
          <v-icon
            x-large
          >
            mdi-sprout
          </v-icon>
          <h3 class="headline">
            Proven approach
          </h3>
          <p class="py-3">
            Methodology successfully applied across 100s of diverse sites
          </p>
          <p class="py-3">
            Make informed land management decisions and monitor results
          </p>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="py-6"
      >
        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          <h2 class="display-2">
            How does it work?
          </h2>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        class="py-6"
      >
        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                1. Create a Covram Cloud account and follow the prompts to create a new Covram project
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-monitor
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                2. Download and install QGIS and install the Mergin Maps plugin to access the project on your desktop
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  mdi-cellphone
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                3. Install the Mergin Maps app on your Android of Apple device to start collecting field data
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn
            large
            rounded
            elevation="2"
            :to="{name: 'getting-started'}"
          >
            Learn More
          </v-btn>
          <v-btn
            large
            rounded
            elevation="2"
            :to="{name: 'register'}"
          >
            Create an account
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'home'
}
</script>

<style scoped lang="scss">
  .sidebar {
    height: 100vh;
    background-color: #4caf50;
    top: 0px;
    max-height: calc(100% + 0px);
    transform: translateX(0%);
    width: 260px;
  }

  .v-navigation-drawer {
    -webkit-overflow-scrolling: touch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;
    top: 0;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    will-change: transform;
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform;
  }

  .bubble {
    width: 800px;
    background-color: #eaebef;
    padding: 20px 25px 15px 15px;
  }

  .app-store-button {
      padding-top: 12px;
    }

  @media only screen and (max-width: 599px) {
    .app-store-button {
      padding-left: 12px;
      padding-top: 0px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 960px) {
    .app-store-button {
      margin-left: 20%;
    }
  }

  h3 {
    color: #2d4470;
  }
</style>
